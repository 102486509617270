body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./images/background.jpg");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.textShadow {
  text-shadow: 4px 4px 11px #6e6c6c;
}

.shadowBox {
  box-shadow: 6px 6px 11px #b8b6b6;
}
.copy {
  font-size: 9px;
  vertical-align: top;
}

.hoverColor:hover {
  font-size: 18px;
  font-weight: bolder;
}

.createBox {
  display: flex;
  justify-content: center;
}
.formBox {
  background-color: rgb(236, 236, 236);
  padding: 15px;

  border-radius: 15px;
}

.filterMe {
  max-width: 300px;
}
.ingredientWidth {
  max-width: 600px;
}

.addIngred {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.recipeName {
  text-shadow: 2px 2px 8px #6d6c6c;
  width: 300px;
}

.recipeType {
  width: 400px;
  display: flex;
  justify-content: center;
  gap: 0px;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  margin-top: 5px;
}

.hideShow{
  display: flex;
  justify-content: center;
  

}

.hideShowBtn{
margin-bottom: 10px;
background-color:transparent;
background-color: #949393;
font-size: 12px;
border: none;
border-radius: 20px;
box-shadow: 0px 5px 8px #929292;

}

.recipeTypeDrop {
  width: 105px;
  height: 35px;
  margin-right: 30px;
}

.directions {
  max-width: 800px;
}
.textArea {
  min-height: 1000px;
}

.recipe_add {
  display:flex; 
  gap: 20px;
  justify-content: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.recipe_add h5 {
  font-size: 15px;
}

.photoText {
color: rgb(175, 11, 11);
font-size: 15px;
font-style: italic;
font-size: 12px;
}

.directions-a {
  font-style: italic;
  font-weight: bold;
}

.directions-b {
  font-size: 14px;
  
}

/* Custom CSS for modal */
.image-modal {
  .modal-dialog {
    max-width: 90vw;
    margin: 0;
  }
  .modal-content {
    border: none;
    border-radius: 0;
  }
  .modal-body {
    padding: 0;
  }
  .modal-body img {
    width: 100%;
    height: auto;
  }
}
